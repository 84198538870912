import 'app/components/warehouse/dataresources.css';
import Link from 'next/link';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import dynamic from 'next/dynamic';
import 'slick-carousel/slick/slick.css';

import CloudinaryImage from '../common/CloudinaryImage';
import Container from '../common/Container';

import 'styles/screenReader.css';
import '../common/common.css';

const Slider = dynamic(() => import('react-slick'));

export default function DataResources({
  resources,
  isEduProduct = false,
  ishome = false,
  ishomeFont = false,
}) {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
        },
      },
    ],
  };

  return (
    <div
      className={`${ishome ? '' : 'md:py-[55px] py-[30px]'} dataresource_slide`}
    >
      <Container className="lg:pl-[1rem] lg:pr-[1rem] pl-[0px] pr-[0px]">
        <div className={`${ishome ? '' : ''}`}>
          {isEduProduct ? (
            <h5 className="relative font-[800] xl:text-[50px] md:text-[32px] text-[26px] title_hd leading-tight text-center max-w-[1100px] mx-auto">
              {resources?.title}
            </h5>
          ) : ishome ? (
            resources?.titleEditor && (
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                className={`${ishomeFont ? 'xl:text-[50px]' : 'xl:text-[50px]'} text-center  md:font-[400] font-[800] md:text-[32px] text-[22px] title_hd leading-tight mt-8`}
                source={resources?.titleEditor}
              >
                {resources?.titleEditor}
              </ReactMarkdown>
            )
          ) : (
            <h5 className="font-[800] text-black xl:text-[50px] lg:text-[32px] text-[22px] text-center leading-tight">
              {resources?.title}
            </h5>
          )}
        </div>

        <div className="w-full">
          <Slider {...settings}>
            {resources?.block
              ? resources?.block.map(data => (
                  <div
                    key={data?.id}
                    className={`dataresource_box ${ishome ? 'shadow-[0px_0px_40px_0px_#0000001A] bg-[#fff] rounded-[8px]' : 'rounded-[30px] shadow-[0px_10px_30px_0px_#8E8EF021] bg-[#F2F5F6]'} overflow-hidden`}
                  >
                    <Link href={`${data?.btn_link}`} target={data?.target}>
                      <div className="w-full md:h-[220px] bg-cover bg-no-repeat  bg-[#10609d0a] dataresorce-img">
                        {data?.image?.data?.attributes.url && (
                          <CloudinaryImage
                            backendImgUrl={data?.image?.data?.attributes.url}
                            alt={data?.image?.data?.alternativeText}
                            type="smallimg"
                            className="h-full object-position-top w-full"
                          />
                        )}
                      </div>
                    </Link>
                    <div className="py-4 px-6">
                      {ishome
                        ? ''
                        : data?.sub_title && (
                            <span className="block text-[#313131] text-[16px] font-[700] mb-3">
                              {data?.sub_title}
                            </span>
                          )}
                      <p
                        className={`${ishome ? 'text-[#000000] md:text-[22px] text-[18px]' : 'mb-5 h-[60px] text-[#0A0D31] text-[20px] '} md:font-[600] font-[700] md:mb-0 mb-3 line-clamp-1`}
                      >
                        <Link
                          href={`${data?.btn_link}`}
                          className="block"
                          target={data?.target}
                        >
                          {data.title}
                        </Link>
                      </p>
                      {ishome && data?.sub_title && (
                        <span
                          className={`block text-[16px] text-[#565656] font-[400] ${ishome ? '' : 'mb-3'}`}
                        >
                          {data?.sub_title}
                        </span>
                      )}
                      {ishome ? (
                        <Link
                          href={`${data?.btn_link}`}
                          className="mt-3 inline-block p-0 text-[#0050D5] text-[16px] font-[400]"
                          target={data?.target}
                        >
                          <span className="screen-reader-text">{`${data?.btn_link}`}</span>
                          {data.btn_text}
                        </Link>
                      ) : (
                        data?.btn_link && (
                          <Link
                            href={`${data?.btn_link}`}
                            className="resorce_btn hidden flex items-center p-0 text-[#0050D5] text-[16px]  font-[700]"
                            target={data?.target}
                          >
                            <div className="mr-3">
                              {data?.btn_icon?.data?.attributes?.url && (
                                <CloudinaryImage
                                  backendImgUrl={
                                    data?.btn_icon?.data?.attributes?.url
                                  }
                                  className="w-[30px]"
                                  alt={
                                    data?.btn_icon?.data?.attributes
                                      ?.alternativeText
                                  }
                                  type="icon"
                                />
                              )}
                            </div>
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                ))
              : resources?.projectList
                ? resources?.projectList?.map((data, key) => (
                    <div
                      key={key}
                      className="dataresource_box rounded-[30px] shadow-[0px_10px_30px_0px_#8E8EF021] bg-[#ffffff] overflow-hidden"
                    >
                      <Link
                        href={`${data?.link ? data?.link : '#'}`}
                        target={data?.target}
                      >
                        <div className="w-full md:h-[220px] bg-cover bg-no-repeat  bg-[#10609d0a] dataresorce-img">
                          {data?.image?.data?.attributes.url && (
                            <CloudinaryImage
                              backendImgUrl={data?.image?.data?.attributes.url}
                              alt={data?.image?.data?.alternativeText}
                              type="smallimg"
                              className="h-full object-position-top w-full"
                            />
                          )}
                        </div>
                      </Link>
                      <div className="lg:py-4 py-2 lg:px-6 px-3">
                        <span className="block text-[#313131] text-[16px] font-[700] mb-3">
                          {data?.sub_title}
                        </span>
                        <Link
                          href={`${data?.link ? data?.link : ''}`}
                          className="block"
                          target={data?.target}
                        >
                          <p
                            className="text-[#0A0D31] lg:text-[24px] text-[18px] font-[600]  mb-5 line-clamp-1 lg:h-[70px] h-[52px]"
                            title={data.title}
                          >
                            {data.title}
                          </p>
                        </Link>
                        <p className="text-[#565656] text-[14px] mb-4">
                          {data?.description}
                        </p>
                        <ul>
                          {resources?.projectTags?.map((items, key) =>
                            items?.key === data?.key ? (
                              <li
                                key={key}
                                className="bg-[#E9E9FF80] text-[12px] text-[#8585FF] py-1 px-3 inline-block rounded-[45px] mb-2 mr-3"
                              >
                                {items?.tagText}
                              </li>
                            ) : (
                              ''
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  ))
                : []}
          </Slider>
        </div>
      </Container>
    </div>
  );
}
